import React, { useEffect } from "react";
import { setHost } from "../../../util/setHost";
import Player from '@vimeo/player'
import css from "./VideoPlayer.module.scss";


export const VideoPlayer = ({video,autoplay,fullwidth,vimeo_url}) => {


    return <div className={fullwidth ? css.wrapperFull : css.wrapper}>
       {vimeo_url ? <VimeoPlayer vimeo_url={vimeo_url} autoplay={autoplay} /> : video ? <EmbeddedPlayer video={video} autoplay={autoplay} fullwidth={fullwidth} /> : null}

</div>
}

const VimeoPlayer = ({vimeo_url,autoplay,fullwidth}) => {
  const vimeo_id = vimeo_url.split('/').pop();
  useEffect(() => {
        var video01Player = new Player(`vimeo-${vimeo_id}`, {id: vimeo_id, autoplay: autoplay,byline:false,responsive:true,vimeo_logo:false,dnt:false});

  },[vimeo_id])

  return (
    <div id={`vimeo-${vimeo_id}`}>hej</div>
  )
}

const EmbeddedPlayer = ({video,autoplay,fullwidth}) => {
    const host = setHost();
const videoUrl = `https://autocirc.se${video.data.attributes.url}`
  return       <video controls={!autoplay} autoPlay={autoplay} muted={!autoplay} style={{width: fullwidth ? '100%' : 'auto'}}>
        <source src={videoUrl} type="video/mp4"/>
      </video>
}